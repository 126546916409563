
import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { InvoiceBasisType } from "@/shared/enums/ClientAccount.enum";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRoute } from "@/shared/useHelpers";
import { PropType, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "InitialInvoiceBasisForm",
  components: {
    BaseModalForm,
  },
  props: {
    modalData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
    invoiceBasisType: {
      type: String as PropType<InvoiceBasisType>,
      required: true,
    },
  },
  emits: ["close", "update"],
  setup(_, { emit }) {
    const route = useRoute();
    const isInternalAofCourse = ref(false);
    const formValues = ref({
      useCourseFee: false,
      isLocalMemberOrganization: true,
    });

    onMounted(async () => {
      isInternalAofCourse.value = (
        await api.clientaccount.doCourseUseInternalAofAsClientAccountInvoiceBasis(+route.params.id)
      ).data;

      if (!isInternalAofCourse.value) {
        formValues.value.useCourseFee = false;
      }
    });

    const updateQueries = () => {
      emit("update", formValues);
    };

    return {
      formatCurrency,
      formValues,
      emit,
      updateQueries,
      isInternalAofCourse,
    };
  },
});
