var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "editable": "",
      "submit-button-text": "Neste"
    },
    on: {
      "close": function close($event) {
        return _vm.emit('close');
      },
      "submit": _vm.updateQueries
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v("Kursavgift")]), _vm.isInternalAofCourse ? _c('v-switch', {
    attrs: {
      "label": _vm.formValues.useCourseFee ? 'Kursavgift' : 'Ingen kursavgift',
      "disabled": !_vm.isInternalAofCourse
    },
    model: {
      value: _vm.formValues.useCourseFee,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "useCourseFee", $$v);
      },
      expression: "formValues.useCourseFee"
    }
  }) : _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({}, on), [_c('v-switch', {
          attrs: {
            "label": _vm.formValues.useCourseFee ? 'Kursavgift' : 'Ingen kursavgift',
            "disabled": !_vm.isInternalAofCourse
          },
          model: {
            value: _vm.formValues.useCourseFee,
            callback: function callback($$v) {
              _vm.$set(_vm.formValues, "useCourseFee", $$v);
            },
            expression: "formValues.useCourseFee"
          }
        })], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Kurset er internt AOF kurs")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v("Lokalt / Sentralt")]), _c('v-switch', {
    attrs: {
      "label": _vm.formValues.isLocalMemberOrganization ? 'Lokalt' : 'Sentralt',
      "disabled": _vm.isInternalAofCourse
    },
    model: {
      value: _vm.formValues.isLocalMemberOrganization,
      callback: function callback($$v) {
        _vm.$set(_vm.formValues, "isLocalMemberOrganization", $$v);
      },
      expression: "formValues.isLocalMemberOrganization"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }