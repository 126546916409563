
import { computed, defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import InvoiceBasisDetails from "@/components/course/economy/ClientAccount/InvoiceBasisDetails.vue";
import PartInvoiceBasisForm from "@/components/course/economy/ClientAccount/PartInvoiceBasisForm.vue";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { StoreState } from "@/store/store.state.interface";
import { useRoute, useStore } from "@/shared/useHelpers";
import { api } from "@/api/api";
import { InvoiceBasisType } from "@/shared/enums/ClientAccount.enum";
import {
  ApiCreateClientAccountInvoiceBasisDto,
  ApiCreateClientAccountInvoicePartBasisDto,
  ApiGetClientAccountInvoiceBasisDto,
  ApiGetMemberOrganizationDto,
} from "@/api/generated/Api";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { openNotification } from "@/shared/helpers/store.helpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";

export default defineComponent({
  name: "CommonMeasuresInvoiceBasisForm",
  components: {
    BaseModalForm,
    InvoiceBasisDetails,
    BaseTableFiltered,
    BaseModal,
    PartInvoiceBasisForm,
  },
  props: {
    modalData: {
      type: Object as PropType<ModalBaseData>,
      required: true,
    },
    invoiceBasisType: {
      type: String as PropType<InvoiceBasisType>,
      required: true,
    },
    templateQueries: {
      type: Object,
      required: true,
    },
  },
  emits: ["close", "update"],
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const editPartInvoiceModalData = ref(getInitialModalData());
    const course = computed(() => store.state.courses.course);
    const invoiceBasis = ref<ApiCreateClientAccountInvoiceBasisDto>({
      createClientAccountInvoicePartBasis: [],
    });
    const memberOrganizations = ref<ApiGetMemberOrganizationDto[]>([]);
    const loadingTemplate = ref<boolean>(false);
    const invoiceBasisTemplate = ref<ApiGetClientAccountInvoiceBasisDto>();

    const getOrgName = (orgId: number) => {
      const memberOrganization = memberOrganizations.value.find((organization) => organization.id === orgId);

      return memberOrganization ? memberOrganization.name : "-";
    };

    const onSubmit = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          if (course.value.id) {
            await api.clientaccount.createClientAccountInvoiceBasis(course.value.id, invoiceBasis.value);
            emit("update");
          }
        } catch (error: any) {
          error.response.data.validationList[0].validationErrors?.forEach((e: any) => {
            openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${e}`);
          });
        }
      });
    };

    const updateInvoicePartBasis = (item: ApiCreateClientAccountInvoicePartBasisDto) => {
      if (!item) {
        return;
      }

      // Find existing member org in form values...
      invoiceBasis.value.createClientAccountInvoicePartBasis =
        invoiceBasis.value.createClientAccountInvoicePartBasis?.filter(
          (x) => x.memberOrganizationId !== item.memberOrganizationId
        );
      // ...and replace with updated values
      invoiceBasis.value.createClientAccountInvoicePartBasis?.push(item);

      // Mutate invoice basis template to show changes in table
      const currentPartBasis = invoiceBasisTemplate.value?.clientAccountInvoicePartBasis?.find(
        (x) => x.memberOrganizationId === item.memberOrganizationId
      );

      if (currentPartBasis) {
        currentPartBasis.numberOfParticipants = item.numberOfParticipants || 0;
        currentPartBasis.shareOfRegisteredCosts = item.shareOfRegisteredCosts || 0;
        currentPartBasis.shareOfCourseFee = item.shareOfCourseFee || 0;
        currentPartBasis.shareOfTotalCosts = item.shareOfCourseFee || 0;
        currentPartBasis.change = item.shareOfRegisteredCosts || 0;
      }

      editPartInvoiceModalData.value.showModal = false;
    };

    const fetchMemberOrganizations = async () => {
      memberOrganizations.value = (await api.organization.getMemberOrganizationsAsync()).data;
    };

    const fetchClientAccountInvoiceTempalte = async () => {
      try {
        if (course.value.id) {
          invoiceBasisTemplate.value = (
            await api.clientaccount.getClientAccountInvoiceBasisTemplate(+route.params.id)
          ).data;
        }
      } catch (error: any) {
        error.response.data.validationList[0].validationErrors?.forEach((e: any) => {
          openNotification(store, NotificationItemType.Error, `Valideringsfeil: ${e}`);
        });
      }
    };

    onMounted(async () => {
      if (course.value.id) {
        loadingTemplate.value = true;
        await Promise.all([fetchMemberOrganizations(), fetchClientAccountInvoiceTempalte()]).finally(() => {
          loadingTemplate.value = false;
        });

        if (invoiceBasisTemplate.value) {
          invoiceBasisTemplate.value.inserted = new Date().toISOString();
          invoiceBasis.value.createClientAccountInvoicePartBasis =
            invoiceBasisTemplate.value.clientAccountInvoicePartBasis?.map((partBasis) => ({ ...partBasis }));
        }
      }
    });

    return {
      onSubmit,
      course,
      invoiceBasis,
      loadingTemplate,
      invoiceBasisTemplate,
      formatCurrency,
      headers: getHeaders(props.invoiceBasisType),
      getOrgName,
      editPartInvoiceModalData,
      displayPartInvoiceModal: useOpenModal(ModalType.Edit, "delgrunnlag", editPartInvoiceModalData),
      updateInvoicePartBasis,
    };
  },
});

const getHeaders = (type: InvoiceBasisType) => [
  { text: "Forbund", value: "memberOrganizationId" },
  { text: "Handlinger", value: "actions" },
  { text: "Antall deltakere fra forbundet", value: "numberOfParticipants" },
  ...(type === InvoiceBasisType.InvoiceBasis
    ? [{ text: "Forbundets andel av registrerte kostnader", value: "shareOfRegisteredCosts" }]
    : [{ text: "Endring", value: "change" }]),
  { text: "Forbundets andel av kursavgift", value: "shareOfCourseFee" },
  { text: "Forbundets samlede kostnad", value: "shareOfTotalCosts" },
];
