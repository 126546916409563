
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import CommonMeasuresInvoiceBasisForm from "@/components/course/economy/ClientAccount/CommonMeasuresInvoiceBasisForm.vue";
import InitialInvoiceBasisForm from "@/components/course/economy/ClientAccount/InitialInvoiceBasisForm.vue";
import { isVocationalSchool as useIsVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { api } from "@/api/api";
import { ApiGetClientAccountInvoiceBasisDto } from "@/api/generated/Api";
import { InvoiceBasisStatus, InvoiceBasisType } from "@/shared/enums/ClientAccount.enum";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { getInvoiceBasisType } from "@/shared/helpers/invoiceBasisHelpers";
import { CourseStatus } from "@/shared/enums/CourseStatus.enum";
import SendToApprovalModal from "@/components/course/economy/ClientAccount/SendToApprovalModal.vue";
import ApproveModal from "@/components/course/economy/ClientAccount/ApproveModal.vue";
import RejectAndDeleteModal from "@/components/course/economy/ClientAccount/RejectAndDeleteModal.vue";
import { authService } from "@/auth/authService";
import { AuthRole } from "@/auth/auth.constants";
import CourseViewFileModal from "@/components/course/details/files/CourseViewFileModal.vue";
import { FilePreviewData } from "@/shared/helpers/fileViewHelpers";

export default defineComponent({
  name: "CourseInvoiceBasisPage",
  components: {
    BaseLayout,
    BaseModal,
    BaseTableFiltered,
    CommonMeasuresInvoiceBasisForm,
    SendToApprovalModal,
    ApproveModal,
    RejectAndDeleteModal,
    CourseViewFileModal,
    InitialInvoiceBasisForm,
  },
  setup() {
    const store = useStore<StoreState>();
    const router = useRouter();
    const route = useRoute();
    const courseId = +route.params.id;
    const course = computed(() => store.state.courses.course);
    const invoiceBases = ref<ApiGetClientAccountInvoiceBasisDto[]>([]);
    const invoiceBasisModalData = ref(getInitialModalData());
    const sendToApproveModalData = ref(getInitialModalData());
    const approveModalData = ref(getInitialModalData());
    const rejectModalData = ref(getInitialModalData());
    const isVocationalSchool = computed(() => useIsVocationalSchool(store.state.plans.studyplan.mainCourseId));
    const canUpsert = ref(isVocationalSchool);
    const nextInvoiceBasisType = ref<InvoiceBasisType>(InvoiceBasisType.InvoiceBasis);
    const canApproveAndReject = authService.hasRoles(AuthRole.ModifyClientAccount);
    const previewModalData = ref(getInitialModalData());
    const initialInvoiceBasisModalData = ref(getInitialModalData());
    const templateQueryValues = ref({
      useCourseFee: false,
      isLocalMemberOrganization: true,
    });
    const loading = ref(false);

    const closeNewInvoiceBasisModal = () => {
      invoiceBasisModalData.value.showModal = false;
      invoiceBasisModalData.value = getInitialModalData();
    };

    const showFilePreviewModal = useOpenModal(ModalType.Unspecified, "", previewModalData);

    const closeAndUpdate = async (modalData: ModalBaseData) => {
      modalData.showModal = false;
      modalData = getInitialModalData();
      await getInvoiceBasis();
    };

    const goToInvoiceBasisDetails = (id: number) =>
      router.push({
        name: SingleCourseRouteNames.CourseInvoiceBasisDetails,
        params: {
          invoiceBasisId: id.toString(),
        },
      });

    const getInvoiceBasis = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        const response = (await api.clientaccount.getClientAccountInvoiceBasis(courseId)).data;
        invoiceBases.value = response.filter((invoiceBasis) => invoiceBasis.status !== InvoiceBasisStatus.Set);

        nextInvoiceBasisType.value = invoiceBases.value.some(
          (x) => x.status === InvoiceBasisStatus.Approved || x.status === InvoiceBasisStatus.Transfered
        )
          ? InvoiceBasisType.Change
          : InvoiceBasisType.InvoiceBasis;
      });
    };

    const generateInvoicepdf = async (item: any) => {
      try {
        const invoiceNumber = item.invoiceBasisNumber || item;
        loading.value = true;

        api.clientaccount.generateClientAccountInvoiceBasis(courseId, invoiceNumber);

        setTimeout(async () => {
          await getInvoiceBasis();
          loading.value = false;
        }, 3000);
      } catch (error) {
        loading.value = false;
      }
    };

    const openNewInvoiceBasisModal = useOpenModal(ModalType.Add, nextInvoiceBasisType.value, invoiceBasisModalData);

    const updateTemplateQueries = (formQueryValues: any) => {
      templateQueryValues.value.isLocalMemberOrganization = formQueryValues.value.isLocalMemberOrganization;
      templateQueryValues.value.useCourseFee = formQueryValues.value.useCourseFee;
      initialInvoiceBasisModalData.value.showModal = false;
      openNewInvoiceBasisModal();
    };

    onMounted(async () => {
      await getInvoiceBasis();
    });

    const getFilePreviewData = (file: any): FilePreviewData => ({
      id: file.fileId,
      name: "Fakturagrunnlag",
      mimeType: "pdf",
    });

    return {
      course,
      courseId,
      invoiceBasisModalData,
      rejectModalData,
      sendToApproveModalData,
      approveModalData,
      canUpsert,
      headers,
      isVocationalSchool,
      invoiceBases,
      InvoiceBasisStatus,
      initialInvoiceBasisModalData,
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
      openNewInvoiceBasisModal,
      openInitalInvoiceBasisModal: useOpenModal(ModalType.Add, "", initialInvoiceBasisModalData),
      openRejectInvoiceBasis: useOpenModal(ModalType.Unspecified, "", rejectModalData),
      openSendToApproveModal: useOpenModal(ModalType.Unspecified, "Send til godkjenning", sendToApproveModalData),
      openApproveModal: useOpenModal(ModalType.Unspecified, "Godkjenn fakturagrunnlag", approveModalData),
      closeNewInvoiceBasisModal,
      templateQueryValues,
      formatLocalizedDateP,
      goToInvoiceBasisDetails,
      closeAndUpdate,
      getInvoiceBasisType,
      getInvoiceBasis,
      showFilePreviewModal,
      previewModalData,
      getFilePreviewData,
      generateInvoicepdf,
      updateTemplateQueries,
      loading,
      canAddInvoiceBasis: computed(
        () =>
          !invoiceBases.value.some(
            (x) => x.status === InvoiceBasisStatus.Draft || x.status === InvoiceBasisStatus.ToApproval
          ) && course.value.status === CourseStatus.Closed
      ),
      nextInvoiceBasisType,
      canApproveAndReject,
      InvoiceBasisType,
      buttonLabel: computed(() =>
        nextInvoiceBasisType.value === InvoiceBasisType.Change
          ? "Opprett endringsgrunnlag"
          : isVocationalSchool
          ? "Opprett fakturagrunnlag"
          : "Opprett hovedgrunnlag"
      ),
    };
  },
});

const headers = [
  { text: "Grunnlagsnummer", value: "invoiceBasisNumber" },
  { text: "Handlinger", value: "actions", sortable: false },
  { text: "Grunnlagsdato", value: "inserted" },
  { text: "Type grunnlag", value: "type" },
  { text: "Genereringsstatus", value: "generationStatus" },
  { text: "Arrangør", value: "courseOrganizer.name" },
  { text: "Fakturagrunnlag", value: "invoiceOuFond" },
  { text: "Status Visma", value: "status" },
];
