var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.previewModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1500
    },
    on: {
      "close": function close() {
        return _vm.previewModalData.showModal = false;
      }
    }
  }, [_c('CourseViewFileModal', {
    attrs: {
      "file": _vm.getFilePreviewData(_vm.previewModalData.existingItem)
    },
    on: {
      "close": function close() {
        return _vm.previewModalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), _vm.course ? _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "outlined": ""
          },
          on: {
            "click": _vm.getInvoiceBasis
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-refresh")]), _vm._v(" Oppdater ")], 1), _vm.canAddInvoiceBasis ? _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.nextInvoiceBasisType === _vm.InvoiceBasisType.Change ? _vm.openNewInvoiceBasisModal() : _vm.openNewInvoiceBasisModal();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")]) : _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('div', _vm._g({}, on), [_c('v-btn', {
                staticClass: "mx-2",
                attrs: {
                  "disabled": "",
                  "outlined": ""
                }
              }, [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")])], 1)];
            }
          }], null, false, 2441844565)
        }, [_c('span', [_vm._v("Kurs må være avsluttet og ingen aktive fakturagrunnlag")])])];
      },
      proxy: true
    }], null, false, 3548010825)
  }, [[_c('div', [_vm.initialInvoiceBasisModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1400,
      "width": 400
    },
    on: {
      "close": function close($event) {
        _vm.initialInvoiceBasisModalData.showModal = false;
      }
    }
  }, [_c('InitialInvoiceBasisForm', {
    attrs: {
      "modalData": _vm.invoiceBasisModalData,
      "invoiceBasisType": _vm.nextInvoiceBasisType
    },
    on: {
      "close": function close($event) {
        _vm.initialInvoiceBasisModalData.showModal = false;
      },
      "update": _vm.updateTemplateQueries
    }
  })], 1) : _vm._e(), _vm.invoiceBasisModalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 1400
    },
    on: {
      "close": _vm.closeNewInvoiceBasisModal
    }
  }, [_c('CommonMeasuresInvoiceBasisForm', {
    attrs: {
      "modalData": _vm.invoiceBasisModalData,
      "invoiceBasisType": _vm.nextInvoiceBasisType,
      "templateQueries": _vm.templateQueryValues
    },
    on: {
      "update": function update($event) {
        return _vm.closeAndUpdate(_vm.invoiceBasisModalData);
      },
      "close": _vm.closeNewInvoiceBasisModal
    }
  })], 1) : _vm._e(), _c('SendToApprovalModal', {
    attrs: {
      "modalData": _vm.sendToApproveModalData,
      "courseId": _vm.courseId
    },
    on: {
      "close": function close($event) {
        _vm.sendToApproveModalData.showModal = false;
      },
      "update": function update($event) {
        return _vm.closeAndUpdate(_vm.sendToApproveModalData);
      }
    }
  }), _c('ApproveModal', {
    attrs: {
      "modalData": _vm.approveModalData,
      "courseId": _vm.courseId
    },
    on: {
      "close": function close($event) {
        _vm.approveModalData.showModal = false;
      },
      "update": function update($event) {
        return _vm.closeAndUpdate(_vm.approveModalData);
      },
      "generateInvoicepdf": _vm.generateInvoicepdf
    }
  }), _c('RejectAndDeleteModal', {
    attrs: {
      "modalData": _vm.rejectModalData,
      "courseId": _vm.courseId
    },
    on: {
      "close": function close($event) {
        _vm.rejectModalData.showModal = false;
      },
      "update": function update($event) {
        return _vm.closeAndUpdate(_vm.rejectModalData);
      }
    }
  }), _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.invoiceBases,
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "title font-weight-light text-center",
          staticStyle: {
            "align-self": "center"
          }
        }, [_vm._v("Fakturagrunnlag")])];
      },
      proxy: true
    }, {
      key: "item.inserted",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDateP(value)) + " ")];
      }
    }, {
      key: "item.generationStatus",
      fn: function fn(_ref3) {
        var value = _ref3.value,
            item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.fileId ? "Generert" : value || "-") + " ")];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm.getInvoiceBasisType(item.invoiceBasisNumber, _vm.invoiceBases)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.goToInvoiceBasisDetails(item.invoiceBasisNumber);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis fakturagrunnlagsdetaljer")])]), item.status === _vm.InvoiceBasisStatus.ToApproval ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.openApproveModal(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-file-document-check")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Godkjenn fakturagrunnlag")])]) : _vm._e(), item.status === _vm.InvoiceBasisStatus.Draft ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                  attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.openSendToApproveModal(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-send")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Send til godkjenning")])]) : _vm._e(), item.status === _vm.InvoiceBasisStatus.Approved && item.fileId ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var on = _ref9.on,
                  attrs = _ref9.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.showFilePreviewModal(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-eye")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Se fakturagrunnlag")])]) : _vm._e(), item.generationStatus == 'generering feilet' && !item.fileId && item.status == 'godkjent' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var on = _ref10.on,
                  attrs = _ref10.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.generateInvoicepdf(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-redo")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Kjør generingen igjen")])]) : _vm._e(), item.status === _vm.InvoiceBasisStatus.Draft || item.status === _vm.InvoiceBasisStatus.ToApproval && _vm.canApproveAndReject ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on,
                  attrs = _ref11.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.openRejectInvoiceBasis(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(_vm._s(item.status === _vm.InvoiceBasisStatus.Draft ? "mdi-delete" : "mdi-file-document-remove"))])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.status === _vm.InvoiceBasisStatus.Draft ? "Slett fakturagrunnlag" : "Avslå fakturagrunnlag"))])]) : _vm._e()];
      }
    }], null, true)
  })], 1)]], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }